<div class="description ns-text small">
  <p>{{ 'user_profile.payments_tab_description_1' | translate }}</p>
  <p>
    {{ 'user_profile.payments_tab_description_2' | translate }}
    <app-button theme="transparent" format="text" size="small" (click)="goToIndividualPaymentsPage()">
      {{ 'user_profile.click_here' | translate }}
    </app-button>.
  </p>
</div>

<app-table
  [table]="table"
  [data]="table.data$ | async"
  [columns]="table.columns"
  [rowsCount]="table.rowsCount"
  [rowsTotal]="table.rowsTotal"
  [loading]="table.loading"
  [page]="table.page"
  [search]="false"
  [emptyStateMessage]="'no_content.no_not_paid_payments' | translate"
  matSort
  (matSortChange)="onSort($event)"
>
  <ng-container [matColumnDef]="Columns.PaymentName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.payment_name' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="ns-scrollable-cell-wrapper">
        <p>{{ row.payment.name }}</p>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.Group">
    <mat-header-cell *matHeaderCellDef>{{ 'table.group' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="center">
      <div class="ns-scrollable-cell-wrapper">
        <p>{{ row.team || '-' }}</p>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.Type">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.type' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="center">
      @switch (row.type) {
        @case (PaymentTypes.RegularPayment) {
          {{ 'economy.other_payment' | translate }}
        }
        @case (PaymentTypes.EventPayment) {
          {{ 'economy.event_payment' | translate }}
        }
        @case (PaymentTypes.MembershipFee) {
          {{ 'economy.membership_fee' | translate }}
        }
        @case (PaymentTypes.TrainingFee) {
          {{ 'economy.training_fee' | translate }}
        }
        @case (PaymentTypes.Dugnad) {
          {{ 'economy.dugnad_payment' | translate }}
        }
      }
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.NetAmount">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.net_amount' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="center">
      {{ (row.net_amount | isNumber) ? (row.net_amount | getNOK | currency) : '-' }}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.PublishDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.publish_date' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="center">{{ row.publish_date | date: DateFormat.shortDate }}</mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.DueDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.due_date' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="center">
      <span [class.ns-expired]="row.is_overdue">{{ row.due_date | date: DateFormat.shortDate }}</span>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.PaymentStatus">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.payment_status' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="center">
      <div class="ns-payment-status-cell">
        @switch (row.paid_status) {
          @case (PaidStatus.PaidManually) {
            <p class="ns-paid">{{ 'payment.paid_manually' | translate }}</p>
          }
          @case (PaidStatus.Paid) {
            <p class="ns-paid">{{ 'payment.paid' | translate }}</p>
          }
          @case (PaidStatus.Exempted) {
            <p>{{ 'payment.exempted' | translate }}</p>
          }
          @case (PaidStatus.Optional) {
            <p>{{ 'payment.optional' | translate }}</p>
          }
          @case (PaidStatus.Declined) {
            <p>{{ 'payment.declined' | translate }}</p>
          }
          @case (PaidStatus.NotPaid) {
            <p class="ns-not-paid">{{ 'payment.not_paid' | translate }}</p>
          }
        }
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.LastReminder">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.last_reminder' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="center" [class.clickable]="row.latest_reminder_date">
      @if (row.latest_reminder_date) {
        <button class="ns-clickable-cell-button" (click)="openReminderHistory(row)">
          {{ row.latest_reminder_date | date: DateFormat.shortDate }}
        </button>
      } @else {
        -
      }
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.LastInvoice">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.last_invoice' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="center">
      {{ row.latest_invoice_date ? (row.latest_invoice_date | date: DateFormat.shortDate) : '-' }}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.Comments">
    <mat-header-cell *matHeaderCellDef>{{ 'table.comments' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="ns-scrollable-cell-wrapper">
        <p>{{ row.note }}</p>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="Columns.Actions">
    <mat-header-cell *matHeaderCellDef class="sticky right"></mat-header-cell>
    <mat-cell *matCellDef="let row" class="sticky right clickable">
      @if (
        (row.paid_status === PaidStatus.Paid ||
          row.paid_status === PaidStatus.PaidManually ||
          (row.type === PaymentTypes.MembershipFee && !userEconomyPermissions.membershipFee.canEdit) ||
          (row.type === PaymentTypes.TrainingFee && !userEconomyPermissions.trainingFee.canEdit)) &&
        !row.latest_reminder_date &&
        !row.latest_invoice_date
      ) {
        <button class="ns-kebab-menu-button" disabled>
          <mat-icon>more_vert</mat-icon>
        </button>
      } @else {
        <button class="ns-kebab-menu-button" appBlurOnClick (click)="menuTrigger.openMenu()">
          <mat-icon #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="moreMenu">more_vert</mat-icon>
        </button>
      }

      <mat-menu #moreMenu="matMenu">
        @if (
          (row.type !== PaymentTypes.Dugnad && row.paid_status === PaidStatus.NotPaid) ||
          (row.type === PaymentTypes.Dugnad &&
            ((row.order_status === OrderStatus.NotSet && row.paid_status !== PaidStatus.Exempted) ||
              row.paid_status === PaidStatus.NotPaid))
        ) {
          <button mat-menu-item (click)="openNewReminder(row)">{{ 'payment.send_reminder' | translate }}</button>
        }

        @if (row.latest_reminder_date) {
          <button mat-menu-item (click)="openReminderHistory(row)">{{ 'payment.reminder_history' | translate }}</button>
        }

        @if (
          (row.type === PaymentTypes.RegularPayment ||
            row.type === PaymentTypes.EventPayment ||
            (row.type === PaymentTypes.MembershipFee && userEconomyPermissions.membershipFee.canEdit) ||
            (row.type === PaymentTypes.TrainingFee && userEconomyPermissions.trainingFee.canEdit)) &&
          (row.paid_status === PaidStatus.NotPaid || row.paid_status === PaidStatus.Optional)
        ) {
          <button mat-menu-item (click)="openNewInvoice(row)">{{ 'economy.generate_new_invoice' | translate }}</button>
        }

        @if (row.latest_invoice_date) {
          <button mat-menu-item (click)="openInvoiceHistory(row)">{{ 'economy.invoice_history' | translate }}</button>
        }

        @if (
          (row.type === PaymentTypes.RegularPayment ||
            row.type === PaymentTypes.EventPayment ||
            (row.type === PaymentTypes.MembershipFee && userEconomyPermissions.membershipFee.canEdit) ||
            (row.type === PaymentTypes.TrainingFee && userEconomyPermissions.trainingFee.canEdit)) &&
          !row.charge_made
        ) {
          <button mat-menu-item (click)="openEditUserPayment(row)">{{ 'action.edit' | translate }}</button>
        }
      </mat-menu>
    </mat-cell>
  </ng-container>
</app-table>

@if (isNewReminderOpen && selectedPayment && selectedPayment.type !== PaymentTypes.Dugnad) {
  <app-new-reminder
    [user]="selectedPayment.user"
    [userPaymentId]="selectedPayment.id"
    (close)="closeNewReminder($event)"
  ></app-new-reminder>
}

@if (isNewReminderOpen && selectedPayment && selectedPayment.type === PaymentTypes.Dugnad) {
  <app-new-dugnad-reminder
    name="{{ selectedPayment.user.first_name }} {{ selectedPayment.user.last_name }}"
    [dugnadType]="selectedPayment.dugnad_info.dugnad_type"
    [orderId]="selectedPayment.dugnad_info.order_id"
    [isConfirmed]="selectedPayment.dugnad_info.is_confirmed"
    [orderStatus]="selectedPayment.dugnad_info.order_status"
    [paidStatus]="selectedPayment.paid_status"
    (close)="closeNewReminder($event)"
  ></app-new-dugnad-reminder>
}

@if (isReminderHistoryOpen && selectedPayment && selectedPayment.type !== PaymentTypes.Dugnad) {
  <app-reminder-history
    [user]="selectedPayment.user"
    [paymentName]="selectedPayment.payment.name"
    [userPaymentId]="selectedPayment.id"
    [paidStatus]="selectedPayment.paid_status"
    (close)="closeReminderHistory($event)"
  ></app-reminder-history>
}

@if (isReminderHistoryOpen && selectedPayment && selectedPayment.type === PaymentTypes.Dugnad) {
  <app-dugnad-reminder-history
    userName="{{ selectedPayment.user.first_name }} {{ selectedPayment.user.last_name }}"
    [dugnadName]="selectedPayment.payment.name"
    [orderId]="selectedPayment.dugnad_info.order_id"
    [isConfirmed]="selectedPayment.dugnad_info.is_confirmed"
    [orderStatus]="selectedPayment.dugnad_info.order_status"
    [paidStatus]="selectedPayment.paid_status"
    (close)="closeReminderHistory($event)"
  ></app-dugnad-reminder-history>
}

@if (isNewInvoiceOpen && selectedPayment) {
  <app-new-invoice
    [user]="selectedPayment.user"
    [paymentName]="selectedPayment.payment.name"
    [userPaymentId]="selectedPayment.id"
    [paymentType]="selectedPayment.type"
    [dueDate]="selectedPayment.due_date"
    [netAmount]="selectedPayment.net_amount"
    [note]="selectedPayment.note"
    (close)="closeNewInvoice($event)"
  ></app-new-invoice>
}

@if (isInvoiceHistoryOpen && selectedPayment) {
  <app-invoice-history
    [user]="selectedPayment.user"
    [paymentName]="selectedPayment.payment.name"
    [userPaymentId]="selectedPayment.id"
    [paymentType]="selectedPayment.type"
    [paidStatus]="selectedPayment.paid_status"
    (close)="closeInvoiceHistory($event)"
  ></app-invoice-history>
}

@if (isEditUserPaymentOpen && selectedPayment && selectedPayment.type !== PaymentTypes.Dugnad) {
  <app-edit-user-payment
    [userPaymentId]="selectedPayment.id"
    (close)="closeEditUserPayment($event)"
  ></app-edit-user-payment>
}

@if (isEditUserPaymentOpen && selectedPayment && selectedPayment.type === PaymentTypes.Dugnad) {
  <app-edit-dugnad-order
    [id]="selectedPayment.dugnad_info.order_id"
    (close)="closeEditUserPayment($event)"
  ></app-edit-dugnad-order>
}
