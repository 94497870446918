import { Component, HostListener, Inject, OnInit } from '@angular/core'
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationSkipped,
  NavigationStart,
  Router
} from '@angular/router'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { Select, Store } from '@ngxs/store'
import { RemoteConfig, getValue } from '@angular/fire/remote-config'
import { Observable, combineLatest, of, timer } from 'rxjs'
import { catchError, filter, finalize, raceWith, switchMap, takeUntil, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

import { GlobalState } from '../../shared/states/global.state'
import { Badges, DashboardState } from './states/dashboard.state'
import { UserProfileState } from '../../shared/modules/user-profile/state/user-profile.state'
import { ToggleUserProfile } from '../../shared/modules/user-profile/state/user-profile.actions'
import { IntercomService, StripeService, UtilsService } from '../../core/services'
import { EconomyTabService, OnboardingApi, OrgService, UsersService } from '../../core/api'
import {
  GetCurrentAdminRelations,
  SetOrganization,
  SetOrganizations,
  SetStripeStatus,
  SetUser,
  ToggleIsSidebarExpanded
} from './states/dashboard.actions'
import { Logout, SetCurrentLanguage } from '../../shared/states/global.actions'
import { StripeAdminTypes } from './tabs/economy-tab/enums/stripe-admin-types'
import { StripeIntegrationStatus } from '../../core/classes/stripe/stripe-integration-status'
import Languages from '../../core/enums/organization/languages'
import { SubMenus } from './enums/sub-menus'
import { BaseComponent } from '../../shared/components/base/base.component'
import { GroupSelectorState, GroupState } from '../../shared/modules/group-selector/states/group-selector.state'
import { WINDOW } from '../../core/services/window.service'
import { DugnadsService } from '../../core/api/dugnads.service'
import { Options } from '../../core/classes/http/options'
import { ChatService } from '../../core/services/chat.service'
import {
  SetUserPermissionsLoading,
  SetUserPermissions,
  SetDugnadAccess
} from './tabs/economy-tab/states/economy-tab.actions'
import { Organization } from '../../core/interfaces/organization/organization'
import { GroupResponse } from '../../core/interfaces/organization/group-response'
import { SelectedGroup } from '../../core/interfaces/organization/selected-group'
import { CurrentUserResponse } from '../../core/interfaces/user/current-user-response'
import { ModulesResponse } from './interfaces/modules-response'
import { RemoteConfigKeys } from '../../core/enums/global/remote-config-keys'
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service'
import { environment } from '../../../environments/environment'
import { DateFormat } from '../../core/enums/global/date-format'
import { Environment } from '../../core/enums/global/environment'
import {
  SetGroupStateLoading,
  UpdateGroupState
} from '../../shared/modules/group-selector/states/group-selector.actions'
import { GroupStateResponse } from '../../core/interfaces/organization/group-state-response'
import { floatingElementAttribute } from '../../shared/directives/floating-element.directive'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: false
})
export class DashboardComponent extends BaseComponent implements OnInit {
  @Select(GroupSelectorState.selectedGroup) selectedGroup$: Observable<SelectedGroup | null>
  @Select(GroupSelectorState.includeSubgroups) includeSubgroups$: Observable<boolean>
  @Select(DashboardState.organization) organization$: Observable<Organization>
  @Select(GlobalState.isLoadingRemoteConfig) isLoadingRemoteConfig$: Observable<boolean>
  @Select(DashboardState.showFotballdataTab) showFotballdataTab$: Observable<boolean>
  @Select(DashboardState.unreadChatMessagesCount) unreadChatMessagesCount$: Observable<number>

  @SelectSnapshot(GlobalState.currentLanguage) currentLanguage: Languages
  @SelectSnapshot(DashboardState.adminGroups) adminGroups: GroupResponse[]
  @SelectSnapshot(DashboardState.user) user: CurrentUserResponse
  // TODO: Use either Observable or static value from State
  @SelectSnapshot(DashboardState.organization) organization: Organization
  @SelectSnapshot(DashboardState.organizations) organizations: Organization[]
  @SelectSnapshot(DashboardState.isSidebarExpanded) isSidebarExpanded: boolean
  @SelectSnapshot(DashboardState.isTopLevelAdmin) isTopLevelAdmin: boolean
  @SelectSnapshot(DashboardState.isDivisionAdmin) isDivisionAdmin: boolean
  @SelectSnapshot(DashboardState.getStripeStatus) stripeStatus: any
  @SelectSnapshot(DashboardState.badges) badges: Badges
  @SelectSnapshot(GroupSelectorState.selectedGroup) selectedGroup: SelectedGroup
  @SelectSnapshot(GroupSelectorState.includeSubgroups) includeSubgroups: boolean
  @SelectSnapshot(GroupSelectorState.groupState) groupState: GroupState
  @SelectSnapshot(UserProfileState.isUserProfileOpen) isUserProfileOpen: boolean

  availableLanguages: Languages[]
  showOrganizationSelector: boolean = false
  adminTypeForStripe: string = ''
  isMenuOpen: boolean = false
  subMenus: { [tab: string]: boolean } = {}
  modules: ModulesResponse
  isStreamChatFeatureEnabled: boolean = false
  buildVersion: string = environment.buildVersion
  buildNumber: number = environment.buildNumber
  buildDate: string = environment.buildDate

  private isNavigationInProgress: boolean = false

  get doShowFotballdata(): boolean {
    return this.organization.compatible_with_fotballdata
  }

  get pageTitleTranslationKey(): string {
    return this.utils.getDeepestPageTitleTranslationKey(this.route) || ''
  }

  get environmentName(): Environment {
    return environment.name
  }

  get RemoteConfigKeys() {
    return RemoteConfigKeys
  }

  get DateFormat() {
    return DateFormat
  }

  get Environment() {
    return Environment
  }

  get Languages() {
    return Languages
  }

  get isStripeUpdateDue() {
    return this.onboardingApi.isStripeUpdateDue(this.stripeStatus, this.adminTypeForStripe)
  }

  get SubMenus() {
    return SubMenus
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private orgService: OrgService,
    private economyTabService: EconomyTabService,
    private snackbarService: SnackbarService,
    private usersService: UsersService,
    private remoteConfig: RemoteConfig,
    private onboardingApi: OnboardingApi,
    private stripeService: StripeService,
    private dugnadsService: DugnadsService,
    private chatService: ChatService,
    private intercomService: IntercomService,
    private utils: UtilsService
  ) {
    super()
  }

  ngOnInit() {
    Object.keys(SubMenus).forEach((key) => (this.subMenus[SubMenus[key]] = false))

    if (this.isSidebarExpanded) {
      this.expandActiveSubMenu()
    }

    this.availableLanguages = this.translate.getLangs() as Languages[]

    this.checkFeatureFlags()

    // TODO: check if there's a way to eliminate this API call
    // when initializing the app from a page with GS
    this.store
      .dispatch(new GetCurrentAdminRelations(this.organization.id))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (!this.selectedGroup) {
          // If app initialized without having selectedGroup in the state.
          this.updateGroupState(this.adminGroups[0].id, false).subscribe()
        }
      })

    this.orgService.getMyOrganizations().subscribe((organizations) => {
      this.store.dispatch(new SetOrganization(organizations.find((org) => org.id === this.organization.id)!))
      this.store.dispatch(new SetOrganizations(organizations))
    })

    this.usersService.getCurrentUser().subscribe((user) => this.store.dispatch(new SetUser(user)))

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.isNavigationInProgress = true
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationSkipped
      ) {
        this.isNavigationInProgress = false
      }

      if (event instanceof NavigationEnd && this.isSidebarExpanded) {
        // Needed when user switches pages using browser's back/forward button
        this.expandActiveSubMenu()
      }
    })

    this.checkStripeStatus()
    this.chatService.initChat(this.user.uuid)
    this.setUpEconomyPermissionFetching()
    this.setUpGroupStateFetching()
    this.usersService.getModules(this.organization.id).subscribe((response) => (this.modules = response))
  }

  private updateGroupState(groupId: number, includeSubgroups: boolean): Observable<any> {
    this.store.dispatch(new SetGroupStateLoading(true))
    return this.orgService.getGroupState(groupId, includeSubgroups).pipe(
      tap((response: GroupStateResponse) =>
        this.store.dispatch(
          new UpdateGroupState({
            memberRequestsCount: response.member_request_count,
            waitingListCount: response.waiting_list_count,
            memberRequestsEnabled: response.member_request_enabled,
            hasMemberRequestsInGroupTree: response.has_member_request_in_group_node
          })
        )
      ),
      finalize(() => this.store.dispatch(new SetGroupStateLoading(false)))
    )
  }

  // Load economy permissions on every selectedGroup change
  private setUpEconomyPermissionFetching(): void {
    this.selectedGroup$
      .pipe(
        filter((group) => !!group),
        tap(() => this.store.dispatch(new SetUserPermissionsLoading(true))),
        switchMap((group) =>
          this.economyTabService.getUserPermissions(group.id).pipe(
            tap((response) =>
              this.store.dispatch([new SetUserPermissionsLoading(false), new SetUserPermissions(response)])
            ),
            catchError(() => of(this.store.dispatch(new SetUserPermissionsLoading(false))))
          )
        ),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe()
  }

  // Load group state on every selectedGroup or includeSubgroups change
  private setUpGroupStateFetching(): void {
    combineLatest([this.selectedGroup$.pipe(filter((group) => !!group)), this.includeSubgroups$])
      .pipe(
        switchMap(([group, includeSubgroups]) =>
          this.updateGroupState(group.id, includeSubgroups).pipe(catchError(() => of()))
        ),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe()
  }

  private checkFeatureFlags(): void {
    // Wait for the remote config to fetch data, but no longer than 5 seconds.
    this.isLoadingRemoteConfig$
      .pipe(
        filter((loading) => !loading),
        raceWith(timer(5000)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.dugnadsService.checkDugnadsPermissions(this.organization.id).subscribe((response: Options) => {
          const permission = new Options().deserialize(response)
          this.store.dispatch(
            new SetDugnadAccess(
              permission.canGet() && getValue(this.remoteConfig, RemoteConfigKeys.FeatureDugnads).asBoolean()
            )
          )
        })

        this.isStreamChatFeatureEnabled = getValue(this.remoteConfig, RemoteConfigKeys.FeatureStreamChat).asBoolean()
      })
  }

  // TODO: find more elegant way
  isRouteActivated(url: string): boolean {
    return this.router.url.includes(url)
  }

  private expandActiveSubMenu(): void {
    switch (true) {
      case this.router.url.includes('/dashboard/membership'):
        this.subMenus[SubMenus.Membership] = true
        break
      case this.router.url.includes('/dashboard/events'):
        this.subMenus[SubMenus.Events] = true
        break
      case this.router.url.includes('/dashboard/communication'):
        this.subMenus[SubMenus.Communication] = true
        break
      case this.router.url.includes('/dashboard/groups'):
        this.subMenus[SubMenus.Groups] = true
        break
      case this.router.url.includes('/dashboard/economy'):
        this.subMenus[SubMenus.Economy] = true
        break
      case this.router.url.includes('/dashboard/accounting'):
        this.subMenus[SubMenus.Accounting] = true
        break
      case this.router.url.includes('/dashboard/locations'):
        this.subMenus[SubMenus.Locations] = true
        break
      case this.router.url.includes('/dashboard/organization'):
        this.subMenus[SubMenus.Organization] = true
        break
    }
  }

  toggleSidebar() {
    this.store.dispatch(new ToggleIsSidebarExpanded()).subscribe((state) => {
      if (state.dashboard.isSidebarExpanded) {
        this.expandActiveSubMenu()
      } else {
        for (const subMenu of Object.keys(this.subMenus)) {
          this.subMenus[subMenu] = false
        }
      }
    })
  }

  toggleSubMenu(event: Event, subMenu: SubMenus): void {
    event.stopPropagation()
    this.subMenus[subMenu] = !this.subMenus[subMenu]
    if (!this.isSidebarExpanded) {
      this.toggleSidebar()
    }
  }

  openUserProfile() {
    this.isMenuOpen = false
    this.store.dispatch(new ToggleUserProfile(true, this.user.id))
  }

  toggleShowOrganizationSelector() {
    if (this.organizations.length > 1) {
      this.showOrganizationSelector = !this.showOrganizationSelector
    } else {
      this.utils.redirectUserTo('dashboard', null, true)
    }
  }

  logout() {
    this.store.dispatch(new Logout())
  }

  changeLanguage(event: Event, language: Languages): void {
    event.stopPropagation()
    this.store.dispatch(new SetCurrentLanguage(language))
  }

  // TODO: check if this is relevant
  private checkStripeStatus() {
    if (!this.organization) return
    this.stripeService.checkStripeVerificationStatus(this.organization.id).subscribe(
      (stripeRes: StripeIntegrationStatus) => {
        this.store.dispatch(new SetStripeStatus(stripeRes))
        this.onboardingApi
          .checkAdminTypeForStripe(this.selectedGroup, this.isTopLevelAdmin)
          .subscribe((adminType: StripeAdminTypes) => {
            this.adminTypeForStripe = adminType
          })
      },
      () => {
        this.store.dispatch(new SetStripeStatus(null))
      }
    )
  }

  openPaymentProvider(): void {
    this.snackbarService.info(this.translate.instant('miscellaneous.redirecting'))
    this.stripeService.getStripeAccountLinkForUpdate(this.organization.id).subscribe((response) => {
      if (response) {
        this.window.open(response.url, '_blank')
      } else {
        this.snackbarService.warning(
          this.translate.instant('dashboard.stripe_not_connected_warning', { organization: this.organization.name })
        )
      }
    })
  }

  openProductboard(): void {
    this.snackbarService.info(this.translate.instant('miscellaneous.redirecting'))
    this.usersService.getProductboardUrl().subscribe((response) => {
      this.window.open(response.uri, '_blank')
    })
  }

  openIntercom(): void {
    this.intercomService.show()
  }

  @HostListener('window:popstate', ['$event'])
  popState(): void {
    if (!document.querySelectorAll(`[${floatingElementAttribute}]`).length) {
      this.isMenuOpen = false
      if (history.state.sidebar) {
        history.back()
      }
    }
  }

  toggleMenu(isOpen?: boolean, event?: MouseEvent) {
    if ((event?.target as HTMLElement)?.classList.contains('ns-sub-menu-wrapper')) return
    this.isMenuOpen = isOpen ?? !this.isMenuOpen

    if (this.isMenuOpen) {
      history.pushState({ sidebar: true }, '')
    } else {
      if (history.state.sidebar && !this.isNavigationInProgress) {
        history.back()
      }
    }
  }
}
